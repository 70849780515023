import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { UserFundApi } from "states/api/userFund";
import logo from "assets/img/logo.png";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate } from "react-router-dom";
import { fundStatus } from "_common/constants/statusType";
import moment from "moment";

export default function ListDataDesktop() {
  const navigate = useNavigate();
  const { data, refetch } = useQuery(
    ["funds", "history"],
    () =>
      UserFundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="acbox contentBK">
      <div className="acrecordTitle">Locked position record</div>
      <div className="acrecordList pd20">
        <div className="acrecordListTitle">
          <span>Project Number</span>
          <span>Currency</span>
          <span>Deposit quantity</span>
          <span>Total Interest</span>
          <span>Lock-up time</span>
          <span>Expiration time</span>
          <span>status</span>
        </div>
        {data?.data?.map((item) => (
          <div className="acrecordListTxt" key={item?.id}>
            <span>{item?.fund.name}</span>
            <span>USDT</span>
            <span>{formatNumber(item?.amount)}</span>
            <span>{formatNumber(item?.amount_payback, "0,0.[000]")}</span>
            <span>{moment(item?.created_at).format("YYYY-MM-DD")}</span>
            <span>
              {formatNumber(item?.fund?.date)} {i18next.t("DAY")}
            </span>
            <span>{i18next.t(fundStatus[item?.status])}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
