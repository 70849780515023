import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi, ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

export default function WalletPassword() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, general, clear },
  } = useStores();

  const schema = yup
    .object({
      wallet_password: yup
        .string()
        .min(6, "Vui lòng nhập mật khẩu tối thiểu 6 ký tự")
        .required("Vui lòng nhập mật khẩu mới"),
      confirmed: yup
        .string()
        .required("Vui lòng nhập lại mật khẩu mới")
        .oneOf([yup.ref("wallet_password")], "Mật khẩu không khớp"),
    })
    .required();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.changePassword({ params: variables }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        navigate(-1);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = () => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  return (
    <div className="container !mt-[50px]">
      <HeaderTop title={i18next.t("change_security_password")} />
      <form
        onSubmit={handleSubmit(onSave)}
        className="no_content !w-[90%] 1mt-[60px] mx-auto"
      >
        <div className="no_inbox">
          {user?.wallet_password && (
            <div className="inputbox boxh">
              <div className="input_title txtl">
                <span className="fzmm fcc">{i18next.t("old_password")}</span>
              </div>
              <div className="input_div">
                <input
                  type="password"
                  name="password"
                  placeholder={i18next.t("please_enter")}
                  className="cinput"
                  {...register("password")}
                />
              </div>
            </div>
          )}
          <div className="inputbox boxh">
            <div className="input_title txtl">
              <span className="fzmm fcc">{i18next.t("new_password")}</span>
            </div>
            <div className="input_div">
              <input
                type="password"
                name="password"
                placeholder={i18next.t("please_enter")}
                className="cinput"
                {...register("wallet_password")}
              />
            </div>
          </div>
          <div className="inputbox boxh">
            <div className="input_title txtl">
              <span className="fzmm fcc">
                {i18next.t("confirmed_password")}
              </span>
            </div>
            <div className="input_div">
              <input
                type="password"
                name="confirm_password"
                placeholder={i18next.t("please_enter")}
                className="cinput"
                {...register("confirmed")}
              />
            </div>
          </div>
          <div className="inputbox boxh">
            <div className="allbtn" id="mo_submit">
              <button type="submit">
                <span>{i18next.t("send")}</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
