import React, { useState } from "react";
import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import i18next from "i18next";

export default function Withdraw() {
  const navigate = useNavigate();
  const {
    authStore: { partners, banks, setBankDeposit },
  } = useStores();

  const [rechargeType, setRechargeType] = useState(2);

  return (
    <div className="container page-deposit">
      <HeaderTop title={i18next.t("withdraw")} />
      <div id="deposit" className="pt-[50px] p-[10px]">
        {/* <Link to="/member/withdraw/submit?symbol=btc">
          <div className="lmcoin">
            <span className=" fcc">BTC</span>
          </div>
        </Link> */}
        <Link to="/member/withdraw/submit?symbol=usdt&network=erc20">
          <div className="lmcoin">
            <span className=" fcc">USDT</span>
          </div>
        </Link>
        {/* <Link to="/member/withdraw/submit?symbol=eth">
          <div className="lmcoin">
            <span className=" fcc">ETH</span>
          </div>
        </Link>
        <Link to="/member/withdraw/submit?symbol=usdc">
          <div className="lmcoin">
            <span className=" fcc">USDC</span>
          </div>
        </Link> */}
      </div>
    </div>
  );
}
