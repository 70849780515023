import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useStores } from "_common/hooks";
import i18next from "i18next";

function ModalInfo({ open, onClose }) {
  const {
    authStore: { general },
  } = useStores();

  return (
    <Portal>
      <div
        className="modal modal-full"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="font-bold">About PFunds</h2>
            <div className="btn-close" onClick={() => onClose()}>
              X
            </div>
          </div>
          <div className="classic-bet-now">
            <div className="content">
              <div className="rules__content">
                <p>
                  PFunds comes from Japan and aims to provide complete financial
                  services for foreign exchange, stock index, gold, financial
                  currency and cryptocurrency. We are committed to providing
                  safe, convenient and diversified services to meet all
                  cryptocurrency needs, including purchase transactions,
                  pledges, loans, etc.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  With the development of blockchain technology and the
                  popularization of artificial intelligence technology, more and
                  more users have demand for investment in digital assets, but
                  due to the high barriers to entry, zero-based users have no
                  clue after the initial contact. PFunds advocates a
                  low-threshold, low-cost, and easy-to-understand method,
                  allowing users to take the first critical step to achieve
                  simple and fast investment operations.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  PFunds is aimed at senior investors, providing programmed and
                  systematic trading solutions, advocating investors to make
                  more rational and professional investment decisions in a
                  self-disciplined, programmable and verifiable way. PFunds
                  provides clients with CFD, gold, energy, foreign exchange, and
                  digital currency margin futures contracts. Unlike futures,
                  margin futures contracts do not have an expiration date. As
                  long as there is sufficient margin in the account, the
                  customer can hold the contract, or until it is traded again.
                  Margin trading is a form of speculation in financial markets
                  that uses leverage to magnify investments. Leverage tools can
                  help clients open positions in the market. Customers only need
                  to invest in margin, and do not need to invest all the funds
                  required to open a position.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  For cryptocurrency spot investors, through PFunds contract
                  transactions, they can easily hedge their assets in the short
                  term with only a small investment. Only in this way can we
                  lock in risks in a volatile market and better grasp profit
                  opportunities.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  In addition, PFunds provides customers with a variety of
                  leverage ratios to meet users various trading styles from
                  stable to aggressive. In particular, the combination of high
                  leverage and low-threshold trading methods improves the time
                  efficiency of transactions, thereby increasing the
                  entertainment of transactions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalInfo;
