import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { FundApi } from "states/api/fund";
import { useStores } from "_common/hooks";
import { formatBalance, formatNumber } from "_common/utils/formatValue";

import { useNavigate } from "react-router-dom";

import ListData from "../Mobile/ListData";
import ListDataDesktop from "../FundHistory/ListDataDesktop";

const FundDesktop = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, display_balance },
  } = useStores();

  const { data, refetch } = useQuery(
    ["funds", "list"],
    () =>
      FundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="fund-detail-page">
      <div className="boximg">
        <div className="boxtitle">
          <article>
            <span>Pledge</span>
          </article>
          <h2>Earn multiple returns</h2>
        </div>
      </div>
      <div className="container mt-5">
        <ListData data={data?.data || []} />
      </div>
      <ListDataDesktop />
    </div>
  );
});

export default FundDesktop;
