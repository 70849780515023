import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCopy,
  faGlobe,
  faWallet,
  faShield,
  faQuestionCircle,
  faBell,
  faArrowLeftLong,
  faUserCheck,
  faCheck,
  faLock,
  faHeadphones,
  faPowerOff,
  faUser,
  faHomeAlt,
  faTimes,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { observer } from "mobx-react-lite";
import i18next from "i18next";
import { handleCopyLink } from "_common/utils/helper";
import bgMember from "assets/img/bg-member.png";
import { formatNumber } from "_common/utils/formatValue";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, game_wallet, general, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <div className="member-page">
      <div className="member-header">
        <div className="mu-card-right" onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="mu-card">
        <div className="mu-card-header">
          <div className="mu-avatar">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="mu-card-header-title">
            <div className="mu-card-title">
              user name：{user?.username}{" "}
              <FontAwesomeIcon icon={faCopy} className="text-[#007AFF]" />
            </div>
            <div className="mu-card-sub-title">
              {i18next.t("credit_score")}: {formatNumber(user?.payment_point)}
            </div>
          </div>
        </div>
      </div>
      <div className="no_inbox lbox_f_br">
        <div className="money-address-info">
          <span>{user?.id}</span>
          <FontAwesomeIcon icon={faCopy} className="text-[#007AFF]" />
        </div>
        <div
          className="link"
          onClick={() => {
            if (user?.is_verify !== 2) {
              navigate("/member/kyc");
            }
          }}
        >
          <div className="lbox">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faUserCheck}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f13 ">
                {i18next.t("name_verification")}
              </span>
            </div>
            <div className="lbox_r">
              <span className="text-[#bec1d2]">
                {user?.is_verify === 2
                  ? i18next.t("approved")
                  : i18next.t("unapproved")}
              </span>

              <FontAwesomeIcon icon={faAngleRight} width="16" color="#fff" />
            </div>
          </div>
        </div>
        <Link to="/member/wallet-password">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faLock}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f13 ">
                {i18next.t("modify_wallet_password")}
              </span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon icon={faAngleRight} width="16" color="#fff" />
            </div>
          </div>
        </Link>
        <Link to="/member/password">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faLock}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f13 ">
                {i18next.t("modify_password")}
              </span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon icon={faAngleRight} width="16" color="#fff" />
            </div>
          </div>
        </Link>
        <Link to="/member/add-wallet">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faLock}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f13 ">{i18next.t("bind_wallet")}</span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon icon={faAngleRight} width="16" color="#fff" />
            </div>
          </div>
        </Link>
        <Link to="/member/bank">
          <div className="lbox ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faLock}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f13 ">{i18next.t("bind_bank")}</span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon icon={faAngleRight} width="16" color="#fff" />
            </div>
          </div>
        </Link>
        <Link to="/cskh">
          <div className="lbox  ">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f13 ">
                {i18next.t("introduction")}
              </span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon icon={faAngleRight} width="16" color="#fff" />
            </div>
          </div>
        </Link>
        <Link to="/member/language">
          <div className="lbox">
            <div className="lbox_l flex items-center">
              <FontAwesomeIcon
                icon={faHeadphones}
                width="18"
                fontSize="18"
                color="#78C9EB"
              />
              <span className="ml-[10px] f13 ">{i18next.t("language")}</span>
            </div>
            <div className="lbox_r">
              <FontAwesomeIcon icon={faAngleRight} width="16" color="#fff" />
            </div>
          </div>
        </Link>
        <div onClick={onLogout}>
          <div className="lbox">
            <div className="lbox_l flex items-center ">
              <FontAwesomeIcon
                icon={faPowerOff}
                width="18"
                fontSize="18"
                color="#f43368"
              />
              <span className="ml-[10px] f13 text-[#f43368]">
                {i18next.t("log_out")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MemberPage;
