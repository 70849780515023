import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { PriceApi } from "states/api/price";
import classNames from "classnames";
import { formatNumber } from "_common/utils/formatValue";
import { useQuery } from "@tanstack/react-query";

const ListCoin = observer(() => {
  const {
    authStore: { games, coins },
  } = useStores();
  const [params] = useSearchParams();
  const symbol = params?.get("symbol");

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const coinListPrice = useMemo(
    () =>
      coins
        ?.filter((obj) => obj?.network_name !== "spot")
        ?.map((item) => {
          const price = data?.find(
            (obj) => obj?.symbol?.toLowerCase() === item?.network_name
          );

          return { ...item, code: item?.network_name, price };
        }),
    [coins, data]
  );

  return (
    <div className="w-full min-h-[90%] bg-[#1a1b1c]">
      <div className="w-full h-full">
        <div className="w-full">
          <div className="dong_sel_box  px-[10px]">
            <span className="fcgs dong_sel_span">Cryptocurrency</span>
          </div>
        </div>
        <div className="symbol_list">
          <div className="usdt-shadow">
            <div className="sy_list_box px-[10px]">
              <div className="sy_list_boxl !text-center">
                <span className="f14 fcgs">Coin</span>
              </div>
              <div className="sy_list_boxl f14 text-[#637085] !text-center">
                Price
              </div>
              <div className="sy_list_boxr f14 text-[#637085]  !text-center">
                Change
              </div>
            </div>
          </div>
          <div className="usdt-shadow">
            {coinListPrice?.map((item) => (
              <Link to={`/board/spot?symbol=${item?.code}`} key={item?.id}>
                <div
                  className="sy_list_box px-[10px]"
                  style={{ background: symbol === item?.code && "#2b3648" }}
                >
                  <div className="sy_list_boxl !text-center">
                    <span className="f12 fcf">
                      {item?.name?.split("/")?.[0]}
                    </span>
                  </div>
                  <div className="sy_list_boxl f12 tcc !text-center">
                    <span
                      className={classNames("fzmm", {
                        green: item?.price?.priceChangePercent > 0,
                        red: item?.price?.priceChangePercent <= 0,
                      })}
                    >
                      {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
                    </span>
                  </div>
                  <div className="sy_list_boxr f12 !text-center">
                    <span
                      className={classNames("fzmm", {
                        green: item?.price?.priceChangePercent > 0,
                        red: item?.price?.priceChangePercent <= 0,
                      })}
                    >
                      {formatNumber(
                        item?.price?.priceChangePercent,
                        "0,0.[00]"
                      )}
                      %
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ListCoin;
