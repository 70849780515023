import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { formatNumber } from "_common/utils/formatValue";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";
import i18next from "i18next";

const Usdt = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games },
  } = useStores();

  const [gameList, setGameList] = useState(games);

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinListPrice = useMemo(
    () =>
      gameList.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [gameList, data]
  );

  return (
    <div className="coin-list">
      <div className="list">
        <div className="item header !static !transform-none !items-start !h-[35px]">
          <div className="col1 text-[#787e8c] !items-start !justify-start">
            {i18next.t("coin_name")}
          </div>
          <div className="col2 text-[#787e8c] !justify-end">
            {i18next.t("last_price")}
          </div>
          <div className="col3 text-[#787e8c] text-right">
            {i18next.t("coin_change")}
          </div>
        </div>
        {coinListPrice?.map((item) => (
          <Link
            to={`/market/detail?symbol=${item?.code}&type=trade`}
            className="item"
            key={item?.id}
          >
            <div className="col1">
              <img
                src={item?.image_url}
                alt=""
                width="20"
                height="20"
                className="mr-[10px]"
              />
              <p className="name">
                <b>{item?.name?.split("/")?.[0]}</b>
                <b>/USDT</b>
              </p>
            </div>
            <div className="col2 !justify-end">
              <span
                className={classNames({
                  "text-green": item?.price?.priceChangePercent > 0,
                  "text-red": item?.price?.priceChangePercent <= 0,
                })}
              >
                {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
              </span>
            </div>
            <div className="col3">
              <span
                className={classNames("tag ", {
                  green: item?.price?.priceChangePercent > 0,
                  red: item?.price?.priceChangePercent <= 0,
                })}
              >
                {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
});

export default Usdt;
