import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useStores } from "_common/hooks";
import icon1 from "assets/img/icon/menu1.svg";
import icon2 from "assets/img/icon/menu2.svg";
import icon3 from "assets/img/icon/menu3.svg";
import icon4 from "assets/img/icon/menu4.svg";
import i18next from "i18next";

export default function MobileFooter() {
  const location = useLocation();
  const pathName = location?.pathname;
  const {
    authStore: { general },
  } = useStores();

  return (
    <div className="footer ">
      <Link
        to="/"
        className={classNames("footer_item", {
          active: pathName === "/",
        })}
      >
        <img src={icon1} alt="" />
        <h5>{i18next.t("page_home")}</h5>
      </Link>
      <Link
        to="/market"
        className={classNames("footer_item", {
          active: pathName === "/market",
        })}
      >
        <img src={icon2} alt="" />
        <h5>{i18next.t("market")}</h5>
      </Link>
      <Link
        to="/contract"
        className={classNames("footer_item", {
          active: pathName === "/contract",
        })}
      >
        <img src={icon3} alt="" />
        <h5>{i18next.t("trading")}</h5>
      </Link>
      <Link
        to="/board/wallet"
        className={classNames("footer_item", {
          active: pathName === "/board/wallet",
        })}
      >
        <img src={icon4} alt="" />
        <h5>{i18next.t("asset")}</h5>
      </Link>
    </div>
  );
}
