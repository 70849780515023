import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import NoData from "_common/component/NoData";
import classNames from "classnames";
import { gameBetItemType } from "_common/constants/statusType";

export default function HistoryTrade({ status }) {
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const { dataGame } = useNotify();

  const { data, refetch } = useQuery(
    ["game-player", "spot", status],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          status,
          type: "spot",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  if (!data?.data?.length) return <NoData />;

  return (
    <div className="mx-auto w-full">
      <div className="list-history-data ">
        {get(data, "data", [])?.map((item) => {
          const time = Number(get(dataGame?.[item?.id], "time", 0));
          const type = Number(get(item, "type", 360));
          const process = (time / type) * 100;
          return (
            <div key={item.id} className="listbox">
              <div className="listbox_title">
                <div className="w-full">
                  <span
                    className={`f14 text-[16px] text-[500] text-[#78C9EB] uppercase ${
                      item?.bet_game === "sell" && "text-[#f5465c]"
                    }`}
                  >
                    <b>{gameBetItemType[item?.bet_game]}</b>
                  </span>
                  <span className="ml-[5px] text-[13px] text-[#888999]">
                    {moment(item?.created_at).format("MM-DD HH:mm:ss")}
                  </span>
                </div>
              </div>
              <div className="w-full h-[60px]">
                <div className="w-[33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px]">
                    <span className="f12 text-[#888999]">
                      {i18next.t("unit_price")}[
                      {item?.game?.name?.split("/")?.[1]}]
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[30px]">
                    <span className="f12 text-[#fff]">
                      {formatNumber(item?.open, "0,0.0000")}
                    </span>
                  </div>
                </div>
                <div className="w-[33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px] text-center">
                    <span className="f12 text-[#888999]">
                      {i18next.t("amount_2")}[
                      {item?.game?.name?.split("/")?.[0]}]
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    <span className="f12 text-[#fff]">
                      {formatNumber(item?.amount, "0,0.[00000]")}
                    </span>
                  </div>
                </div>
                <div className="w-[33%] h-[60px] inline-block">
                  <div className="w-full h-[30px] leading-[40px] text-right">
                    <span className="f12 text-[#888999]">
                      {i18next.t("dealed")}[{item?.game?.name?.split("/")?.[0]}]
                    </span>
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-right">
                    <span className="f12 text-[#fff]">
                      {formatNumber(item?.amount_payback, "0,0.[00000]")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
