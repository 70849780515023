import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import FeatureMobile from "./Mobile/FeatureMobile";
import FeatureDesktop from "./Desktop/FeatureDesktop";

const FeaturePage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <FeatureMobile />;
  return <FeatureDesktop />;
});

export default FeaturePage;
