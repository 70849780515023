import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { UserFundApi } from "states/api/userFund";
import logo from "assets/img/logo.png";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import ListDataMobile from "./ListDataMobile";
import ListDataDesktop from "./ListDataDesktop";

export default function FundHistory() {
  const navigate = useNavigate();
  const { isMobile } = useMediaQuery();
  const { data, refetch } = useQuery(
    ["funds", "history"],
    () =>
      UserFundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="fund-history">
      <HeaderTop title={i18next.t("entrustment_order")} />
      {isMobile ? <ListDataMobile /> : <ListDataDesktop />}
    </div>
  );
}
