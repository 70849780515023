import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import i18next from "i18next";
import General from "./General";
import ContractAssets from "./ContractAssets";
import TradingAssets from "./TradingAssets";

const WalletPage = observer(() => {
  const [activeTab, setActiveTab] = useState("platform");
  return (
    <div className="page-wallet py-[50px]">
      <div className="wallet-header">
        <div className="tabs">
          <div
            className={classNames("tab", {
              active: activeTab === "platform",
            })}
            onClick={() => setActiveTab("platform")}
          >
            {i18next.t("platform_assets")}
          </div>
          <div
            className={classNames("tab", {
              active: activeTab === "contract",
            })}
            onClick={() => setActiveTab("contract")}
          >
            {i18next.t("contract_assets")}
          </div>
          <div
            className={classNames("tab", {
              active: activeTab === "trading",
            })}
            onClick={() => setActiveTab("trading")}
          >
            {i18next.t("trading_assets")}
          </div>
        </div>
      </div>
      {activeTab === "platform" && <General />}
      {activeTab === "contract" && <ContractAssets />}
      {activeTab === "trading" && <TradingAssets />}
    </div>
  );
});

export default WalletPage;
