import { get } from "lodash";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class AuthStore {
  token = "";

  user = null;

  loading = false;

  theme_light = false;

  open_music = true;

  bank_select = {};

  display_balance = true;

  device = "";

  avatars = [];

  coin_options = [];

  games = [];

  banks = [];

  tokens = [];

  banners = [];

  coins = [];

  partners = [];

  ieo = [];

  questions = [];

  languages = [];

  top_coin = [];

  user_wallet = {};

  game_wallet = {};

  bank_default = {};

  general = {};

  bank_deposit = {};

  is_verify = false;

  logo = "";

  banner_login_footer = "";

  banner_login_top = "";

  banner_register_top = "";

  banner_register_footer = "";

  image_introduction = "";

  language = "en";

  line = "line1";

  trading_view = "";

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AuthStore",
      properties: [
        "token",
        "user",
        "device",
        "theme_light",
        "open_music",
        "display_balance",
        "language",
        "is_verify",
      ],
      storage: window.localStorage,
    });
  }

  get authenticated() {
    // TODO: This logic can be changed on demand
    return !!this.token;
  }

  clear = () => {
    this.token = "";
    this.user = null;
  };

  setInitData = (data) => {
    this.device = data?.device_code;
    this.trading_view = data?.trading_view;
    this.banners = data?.banners || [];
    this.games = data?.games || [];
    this.top_coin = data?.top_coin || [];
    this.coins = data?.coins || [];
    this.avatars = data?.avatars || [];
    this.partners = data?.partners || [];
    this.questions = data?.questions || [];
    this.languages = data?.languages || [];
    this.banks = data?.banks || [];
    this.ieo = data?.ieo || [];

    this.banner_login_footer = data?.banner_login_footer;
    this.banner_login_top = data?.banner_login_top;
    this.banner_register_top = data?.banner_register_top;
    this.banner_register_footer = data?.banner_register_footer;
    this.image_introduction = data?.image_introduction;
    const general = data?.general || [];

    if (Array.isArray(general)) {
      const logoData = general?.find((obj) => obj?.name === "logo_dark");
      if (logoData) {
        this.logo = logoData?.image_url;
      }
    }

    const newsValue = {};

    general.forEach((item, index) => {
      newsValue[item.name] = item.value;
      if (item.value === "0") {
        newsValue[item.name] = false;
      }
    });

    this.general = newsValue;
  };

  setCoinOption = (coin) => {
    const checkIndex = this.coin_options?.findIndex((obj) => obj === coin);
    if (checkIndex) {
      this.coin_options.splice(checkIndex, 1);
    } else {
      this.coin_options.push(coin);
    }
  };

  setIsVerify = (status) => {
    this.is_verify = status;
  };

  setBankDeposit = (data) => {
    this.bank_deposit = data;
  };

  setBankSelect = (value) => {
    this.bank_select = value;
  };

  updateToken = (token) => {
    this.token = token || this.token;
  };

  setLoading = (open) => {
    this.loading = open;
  };

  setThemeLight = (open) => {
    this.theme_light = open;
    window.location.reload();
  };

  setOpenMusic = (open) => {
    this.open_music = open;
    window.location.reload();
  };

  setDisplayBalance = (open) => {
    this.display_balance = open;
    // window.location.reload();
  };

  setLanguage = (value) => {
    this.language = value;
    window.location.reload();
  };

  setLine = (value) => {
    this.line = value;
    window.location.href = "/";
  };

  updateUser = (data) => {
    const tokens = get(data.user, "tokens", []) || [];
    this.user = data.user;
    this.token = data?.token || this.token;
    this.tokens = tokens;
    this.bank_default = data?.user?.banks?.[0];
    this.user_wallet = tokens?.find((obj) => obj.symbol === "usdt");
    this.game_wallet = tokens?.find((obj) => obj.symbol === "game");
  };
}
