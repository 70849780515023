import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { handleCopyLink } from "_common/utils/helper";
import UploadFile from "_common/component/UploadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNotify } from "_common/component/NotifyProvider";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import QRCode from "react-qr-code";
import classNames from "classnames";

const schema = yup
  .object({
    amount: yup.number().required(i18next.t("verify_require")),
  })
  .required();

export default function Transfer() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { pushNotify } = useNotify();

  const [from_wallet, setFromWallet] = useState("amount");
  const [to_wallet, setToWallet] = useState("trading_amount");

  const walletTypes = {
    option_amount: i18next.t("option_account"),
    trading_amount: i18next.t("trading_account"),
    amount: i18next.t("spot_account"),
  };

  const {
    authStore: { user, general, banks, game_wallet, coins },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.transfer({
        params: {
          ...variables,
          symbol: "usdt",
          to: to_wallet,
          from: from_wallet,
          type: "transfer",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
        pushNotify({
          type: "transfer",
        });
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    onSubmit({ ...values });
    return null;
  };

  return (
    <div className="container">
      <HeaderTop title={i18next.t("transfer")} />
      <div id="withdraw" className="pt-[30px] px-[15px]">
        <form className="imgbox" onSubmit={handleSubmit(onSave)}>
          <div className="imgbox_4 !border-[0] !mt-[0]">
            <div className=" text-[#A5A5A5]">
              <span className="fzmm ">Select currency</span>
            </div>
            <div className="zz_inputbox mb-[15px]">
              <select className="zz_input">
                <option>USDT</option>
              </select>
            </div>
            <div className=" text-[#A5A5A5]">
              <span className="fzmm ">From</span>
            </div>
            <div className="zz_inputbox mb-[15px]">
              <select
                className="zz_input"
                value={from_wallet}
                onChange={(e) => setFromWallet(e?.target?.value)}
              >
                {Object.keys(walletTypes).map((item) => (
                  <option key={item} value={item}>
                    {walletTypes[item]}
                  </option>
                ))}
              </select>
            </div>
            <div className=" text-[#A5A5A5]">
              <span className="fzmm ">To</span>
            </div>
            <div className="zz_inputbox mb-[15px]">
              <select
                className="zz_input"
                value={to_wallet}
                onChange={(e) => setToWallet(e?.target?.value)}
              >
                {Object.keys(walletTypes).map((item) => (
                  <option key={item} value={item}>
                    {walletTypes[item]}
                  </option>
                ))}
              </select>
            </div>
            <div className=" text-[#A5A5A5]">
              <span className="fzmm ">{i18next.t("amount_3")}</span>
            </div>
            <div className="zz_inputbox">
              <input
                type="number"
                inputMode="decimal"
                min={0}
                step={0.000001}
                className="zz_input"
                {...register("amount")}
              />
              <span className="text-[#A5A5A5]">USDT</span>
              <span className="px-[10px] text-[#A5A5A5]">|</span>
              <span
                className="text-[#A5A5A5]"
                onClick={() => {
                  if (from_wallet === "option_amount") {
                    setValue("amount", Number(user?.balance_option));
                  } else if (from_wallet === "trading_amount") {
                    setValue("amount", Number(user?.balance_trading));
                  } else {
                    setValue("amount", Number(user?.balance));
                  }
                }}
              >
                All
              </span>
            </div>
            <div className="h-[40px] flex justify-between items-center">
              <div className="w-[50%] h-[40px] leading-[40px]">
                <span className="text-[13px] text-[#A5A5A5]">
                  {i18next.t("usable")}:{" "}
                </span>
                {from_wallet === "option_amount" && (
                  <span className="text-[13px] text-[#78C9EB]">
                    {formatNumber(user?.balance_option, "0,0.0000")}
                  </span>
                )}
                {from_wallet === "trading_amount" && (
                  <span className="text-[13px] text-[#78C9EB]">
                    {formatNumber(user?.balance_trading, "0,0.0000")}
                  </span>
                )}
                {from_wallet === "amount" && (
                  <span className="text-[13px] text-[#78C9EB]">
                    {formatNumber(user?.balance, "0,0.0000")}
                  </span>
                )}
              </div>
            </div>
            <div className="imgbox_8 mt-[10px]">
              <div className="imgbox_10" id="sumbtn">
                <button
                  type="submit"
                  className="fzmm w-full uppercase"
                  disabled={!isValid}
                >
                  {i18next.t("transfer")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
