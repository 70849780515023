import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import classNames from "classnames";

const GameNavTop = observer(({ type }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games },
  } = useStores();

  const [activeTab, setActiveTab] = useState(type || "contract");

  return (
    <div className="market-header">
      <div className="tabs">
        <div
          className={classNames("tab", {
            active: activeTab === "contract",
          })}
          onClick={() => navigate("/contract")}
        >
          {i18next.t("second_contract")}
        </div>
        <div
          className={classNames("tab", {
            active: activeTab === "trade",
          })}
          onClick={() => navigate("/board/spot")}
        >
          {i18next.t("coin_trade")}
        </div>
        <div
          className={classNames("tab", {
            active: activeTab === "standard",
          })}
          onClick={() => navigate("/feature")}
        >
          {i18next.t("standard_contract")}
        </div>
      </div>
    </div>
  );
});

export default GameNavTop;
