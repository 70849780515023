import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { useNotify } from "_common/component/NotifyProvider";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import imgEmpty from "assets/img/empty-dark.png";

import GameChart from "./GameChart";
import HistoryPrice from "./HistoryPrice";
import TotalStation from "./TotalStation";
import HistorySpot from "./HistorySpot";
import ListCoin from "./ListCoin";
import GameHeader from "./GameHeader";
import GameBoard from "./GameBoard";

const SpotTradeDesktopPage = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();

  const navigate = useNavigate();
  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";
  const time = params?.get("time") || "1m";
  const theme = params?.get("theme") || "dark";

  const [game_id, setGameId] = useState(games[0]?.code);
  const [isLight, setIsLight] = useState(false);

  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  useEffect(() => {
    if (!theme) return;
    setIsLight(theme === "light");
  }, [theme]);

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  return (
    <div className="css-1wr4jig !bg-[#151617] !border-t-[1px] !border-[#151617]">
      <GameHeader game_id={game_id} data_price={priceBtc} />
      <div className="w-full min-h-[880px] flex">
        <div className="w-[280px] min-h-[900px] pr-[10px] float-left">
          <ListCoin />
        </div>
        <div className="min-h-[90%] float-left flex-1">
          <GameChart game_id={game_id} isLight={isLight} />

          <GameBoard game_id={game_id} data_price={priceBtc} />
        </div>
        <div className="w-[250px] min-h-[800px] pl-[10px] float-left">
          <div className="w-full min-h-[90%] bg-[#1a1b1c]">
            <div className="w-full h-[40px] px-[15px]">
              <div className="h-[40px] leading-[40px] px-[5px]">
                <span className="f14 text-[#1da2b4]">Order book</span>
              </div>
              <div className="w-full mt-[20px]">
                <HistoryPrice />
              </div>
            </div>
          </div>
        </div>
        <div className="w-[250px] min-h-[800px] pl-[10px] float-left">
          <div className="w-full min-h-[90%] bg-[#1a1b1c]">
            <div className="w-full h-[40px] px-[15px]">
              <div className="h-[40px] leading-[40px] px-[5px]">
                <span className="f14 text-[#1da2b4]">Total station</span>
              </div>
              <div className="w-full mt-[20px]">
                <TotalStation />
              </div>
            </div>
          </div>
        </div>
      </div>
      <HistorySpot />
    </div>
  );
});

export default SpotTradeDesktopPage;
