import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import FundMobile from "./Mobile/FundMobile";
import FundDesktop from "./Desktop/FundDesktop";

const FundPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <FundMobile />;
  return <FundDesktop />;
});

export default FundPage;
