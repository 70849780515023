import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import ModalBlock from "pages/Home/ModalBlock";
import ModalBonus from "pages/Home/ModalBonus";
import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import Loading from "_common/component/Loading";
import { useNotify } from "_common/component/NotifyProvider";
import { useStores } from "_common/hooks";
import MobileFooter from "../Footer/MobileFooter";
import HeaderDesktop from "../Header/HeaderDesktop";
import DesktopFooter from "../Footer/DesktopFooter";

const DesktopLayout = observer(() => {
  const queryClient = useQueryClient();
  const { notify, setNotify } = useNotify();
  const {
    authStore: { user, loading },
  } = useStores();
  const [openModalNotify, setModalNotify] = useState(false);
  const [openModalBlock, setModalBlock] = useState(false);
  const [dataNotice, setDataNotice] = useState();

  useEffect(() => {
    if (notify?.data?.data?.user_id === user?.id) {
      queryClient.invalidateQueries(["get_profile"]);
      setNotify();
    }
  }, [notify, user]);

  useEffect(() => {
    if (
      notify?.data?.type === "send_notice" &&
      notify?.data?.data?.user_id === user?.id
    ) {
      setDataNotice(notify?.data?.data);
      if (typeof poof === "function") {
        // eslint-disable-next-line no-undef
        poof();
      }
      queryClient.invalidateQueries(["game-player", "trade"]);
      queryClient.invalidateQueries(["game-player", "spot"]);
      setModalNotify(true);
      setNotify();
    }
  }, [notify, user]);

  useEffect(() => {
    if (!user?.status) {
      setModalBlock(true);
    } else {
      setModalBlock(false);
    }
  }, [user?.status]);

  return (
    <div className="tw-dark">
      <div className="desktop-layout font-['Roboto']  text-white w-full h-full">
        <HeaderDesktop />
        <div className="flex w-full h-full">
          <div className="flex flex-col w-full h-screen max-h-full min-h-full transition-all desktop-body">
            <div
              className={classNames("sbui-loading ", {
                "sbui-loading--active": loading,
              })}
            >
              <div className="loading-mask" />
              <div className="sbui-loading-content">
                <Outlet />
                <DesktopFooter />
              </div>
              {loading && <Loading />}
            </div>
          </div>
        </div>
      </div>
      {openModalNotify && (
        <ModalBonus
          open={openModalNotify}
          onClose={() => setModalNotify(false)}
          data={dataNotice}
        />
      )}
    </div>
  );
});

export default DesktopLayout;
