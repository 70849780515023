import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { useNotify } from "_common/component/NotifyProvider";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import ModalBet from "../Mobile/ModalBet";

const GameBoard = observer(({ game_id, data_price }) => {
  const {
    authStore: { games, user },
  } = useStores();

  const [openModal, setOpenModal] = useState(false);
  const [game_type, setGameType] = useState();
  const [buyType, setType] = useState("market");
  const [priceBuy, setPriceBuy] = useState();
  const [priceSell, setPriceSell] = useState();
  const [amountBuy, setAmountBuy] = useState();
  const [amountSell, setAmountSell] = useState();

  useEffect(() => {
    setPriceBuy(data_price?.lastPrice);
    setPriceSell(data_price?.lastPrice);
  }, [data_price]);

  const handleBetGame = (type) => {
    setGameType(type);
    setOpenModal(true);
  };

  return (
    <div className="w-full h-[360px] border-t-[5px] border-[#151617]">
      <div className="w-full h-[360px]">
        <div className="contentbox !w-full !p-[0]">
          <div className="tradebox !w-full !p-[0]">
            <div className="flex justify-between items-center h-[37px] bg-[#1f1f1f] px-5">
              <div>
                {formatNumber(user?.balance, "0,0.[00000]")} USDT /{" "}
                {formatNumber(0)} BTC
              </div>
              <div>
                <Link className="text-[#c7cce6]" to="/member/recharge">
                  Deposit
                </Link>
                <Link className="text-[#c7cce6]" to="/member/transfer">
                  Transfer
                </Link>
              </div>
            </div>
            <div className="formbox_op">
              <div
                className={`formbox_op_list jy-btn ${
                  buyType === "limit" && "btn_bg_color"
                }`}
                onClick={() => setType("limit")}
              >
                Limit
              </div>
              <div
                className={`formbox_op_list jy-btn ${
                  buyType === "market" && "btn_bg_color"
                }`}
                onClick={() => setType("market")}
              >
                Market
              </div>
            </div>
            <div className="tradebox_l !w-full !p-[0] flex">
              <div className="formbox max-w-[50%] px-[15px]">
                <div className="input-item ">
                  <span className="label">Price</span>
                  <div className="input-group">
                    <input
                      type="number"
                      inputMode="decimal"
                      placeholder="Market optimal price"
                      value={buyType === "limit" && priceBuy}
                      disabled={buyType === "market"}
                    />
                    <span>USDT</span>
                  </div>
                </div>
                <div className="input-item  mt-[15px]">
                  <span className="label">Amount</span>
                  <div className="input-group">
                    <input
                      type="number"
                      inputMode="decimal"
                      placeholder="Market optimal price"
                      value={amountBuy}
                      onChange={(e) => setAmountBuy(e?.target?.value)}
                    />
                    <span>BTC</span>
                  </div>
                </div>
                <div className="mt-[10px] text-[#c7cce6]">
                  Volume {formatNumber(amountBuy * priceBuy, "0,0.[0000]")} USDT
                </div>
                <button
                  type="button"
                  onClick={() => handleBetGame("buy")}
                  className="w-full h-[36px] leading-[36px] text-white text-center !bg-[#35b65a] rounded-[5px] mt-[10px]"
                >
                  <span className="f12 !text-white">Buy BTC</span>
                </button>
              </div>
              <div className="formbox max-w-[50%] px-[15px]">
                <div className="input-item ">
                  <span className="label">Price</span>
                  <div className="input-group">
                    <input
                      type="number"
                      inputMode="decimal"
                      placeholder="Market optimal price"
                      value={buyType === "limit" && priceSell}
                      disabled={buyType === "market"}
                    />
                    <span>USDT</span>
                  </div>
                </div>
                <div className="input-item mt-[15px]">
                  <span className="label">Amount</span>
                  <div className="input-group">
                    <input
                      type="number"
                      inputMode="decimal"
                      placeholder="Market optimal price"
                      value={amountSell}
                      onChange={(e) => setAmountSell(e?.target?.value)}
                    />
                    <span>BTC</span>
                  </div>
                </div>
                <div className="mt-[10px] text-[#c7cce6]">
                  Volume {formatNumber(amountSell * priceSell, "0,0.[0000]")}{" "}
                  USDT
                </div>
                <button
                  type="button"
                  onClick={() => handleBetGame("sell")}
                  className="w-full h-[36px] leading-[36px] text-white text-center !bg-[#ff494a] [px-[15px] rounded-[5px] mt-[10px]"
                >
                  <span className="f12 !text-white">Sell BTC</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalBet
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={{
            game_id,
            game_type,
            amount: game_type === "buy" ? amountBuy : amountSell,
          }}
        />
      )}
    </div>
  );
});

export default GameBoard;
