import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import w1 from "assets/img/why/w1.png";
import w2 from "assets/img/why/w2.png";
import w3 from "assets/img/why/w3.png";
import w4 from "assets/img/why/w4.png";
import w5 from "assets/img/why/w5.png";

const Why = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { isMobile } = useMediaQuery();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <div className="why">
      <p className="why-title">Why PFunds?</p>
      <ul className="why-item">
        <li>
          <img src={w1} alt="" className="why-item-image" />
          <p className="why-item-title">Innovative Products</p>
          <p className="why-item-desc">One-Click Copy Trading</p>
          <p className="why-item-desc">Trade without converting tokens</p>
          <p className="why-item-desc">
            The leading derivative exchange supporting USDC margin
          </p>
        </li>
        <li>
          <img src={w2} alt="" className="why-item-image" />
          <p className="why-item-title">Industry-leading Security</p>
          <p className="why-item-desc">
            Risk Control with hot &amp; cold wallets segregation
          </p>
          <p className="why-item-desc">T2 A+ ratings from SSL Labs</p>
          <p className="why-item-desc">
            Trusted security backed by Suntwin Technology, Qingsong Cloud
            Security, HEAP, and Armors
          </p>
        </li>
        <li>
          <img src={w3} alt="" className="why-item-image" />
          <p className="why-item-title">Excellent Customer Service</p>
          <p className="why-item-desc">24/7 multilingual online support</p>
          <p className="why-item-desc">1-on-1 support for VIP customers</p>
          <p className="why-item-desc">Reward Centers dedicated to community</p>
        </li>
        <li>
          <img src={w4} alt="" className="why-item-image" />
          <p className="why-item-title">Dedicated Derivatives Exchange</p>
          <p className="why-item-desc">Self-developed trade pairing system</p>
          <p className="why-item-desc">
            Multiple first-of-its-kind derivatives products
          </p>
          <p className="why-item-desc">
            Top 6 futures exchange by trading volume
          </p>
        </li>
        <li>
          <img src={w5} alt="" className="why-item-image" />
          <p className="why-item-title">Global Compliance Operations</p>
          <p className="why-item-desc">
            Obtained licenses in the U.S, Canada, and Australia
          </p>
          <p className="why-item-desc">Strong regulatory standards</p>
          <p className="why-item-desc">Listed on CMC and CoinGecko</p>
        </li>
      </ul>
    </div>
  );
});

export default Why;
