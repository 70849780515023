import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { UserFundApi } from "states/api/userFund";
import logo from "assets/img/logo.png";
import { formatNumber } from "_common/utils/formatValue";
import { useNavigate } from "react-router-dom";
import { fundStatus } from "_common/constants/statusType";
import moment from "moment";

export default function ListDataHistory() {
  const navigate = useNavigate();
  const { data, refetch } = useQuery(
    ["funds", "history"],
    () =>
      UserFundApi.getList({
        params: {
          page: 1,
          limit: 50,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="list-history-data">
      {data?.data?.map((item) => (
        <div key={item?.id} className="item">
          <div className="flex  justify-between items-center gap-2 bg-[ #1678ff] text-white py-[10px] px-[12px]">
            <h3>{item?.fund.name}</h3>
          </div>
          <div className="flex  justify-between items-center gap-2 mt-5 px-[12px]">
            <div className="flex flex-col items-center flex-1">
              <h4 className="text-sm text-center">
                {i18next.t("Number of Deposits")}
              </h4>
              <span className="text-sm   text-center text-blue-500">
                {formatNumber(item?.amount)}
              </span>
            </div>
            <div className="flex flex-col items-center flex-1">
              <h4 className="text-sm text-center">
                {i18next.t("daily_earnings_rate")}
              </h4>
              <span className="text-sm   text-center text-blue-500">
                {formatNumber(item?.fund?.max_profit, "0,0.[000]")}%
              </span>
            </div>
            <div className="flex flex-col items-center flex-1">
              <h4 className="text-sm text-right">{i18next.t("game_no")}</h4>
              <span className="text-sm   text-right text-blue-500">
                {formatNumber(item?.fund?.date)} {i18next.t("DAY")}
              </span>
            </div>
          </div>
          <div className="flex  justify-between items-center gap-2 mt-5 px-[12px]">
            <div className="flex flex-col items-center flex-1">
              <h4 className="text-sm text-center">{i18next.t("start_day")}</h4>
              <span className="text-sm   text-center text-blue-500">
                {moment(item?.created_at).format("YYYY-MM-DD")}
              </span>
            </div>
            {/* <div className="flex flex-col items-center flex-1">
            <h4 className="text-sm text-center">
              {i18next.t("finish_time")}
            </h4>
            <span className="text-sm   text-center text-blue-500">
              {moment(item?.created_at)
                .add(Number(get(item, "fund.date", 0)), "d")
                .format("YYYY-MM-DD")}
            </span>
          </div> */}
            <div className="flex flex-col items-center flex-1">
              <h4 className="text-sm text-center">
                {i18next.t("Total revenue")}
              </h4>
              <span className="text-sm   text-center text-blue-500">
                {formatNumber(item?.amount_payback, "0,0.[000]")}
              </span>
            </div>
            <div className="flex flex-col items-center flex-1">
              <h4 className="text-sm text-center">{i18next.t("Status")}</h4>
              <span className="text-sm   text-center text-blue-500">
                {i18next.t(fundStatus[item?.status])}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
