import React, { useRef, useState } from "react";
import { endpoint } from "_common/constants/common";
import { get } from "lodash";
import api from "states/drivers";
import iconUpImg from "assets/img/icon/upimg.png";

function UploadFile({
  onReceiveImages = (data) => {},
  image,
  disabled,
  ...props
}) {
  const ref = useRef();

  const [fileListState, setFileListState] = useState([]);

  const handleUploadFailed = (res) => {
    console.log("upload failed", res);

    const { file } = res;
    const data = [...fileListState];
    const index = data.findIndex((i) => i?.uid === file?.uid);
    data.splice(index, 1);

    setFileListState(data);
  };

  const handleSuccess = ({ url, id, uid, file }) => {
    const newImage = {
      isNew: true,
      id,
      uid,
      name: get(file, "name"),
      status: "done",
      url,
    };
    const dataImages = [...fileListState, newImage];

    setFileListState(dataImages);
    if (typeof onReceiveImages === "function") {
      onReceiveImages(dataImages);
    }
  };

  const onFileUpload = async (file) => {
    try {
      if (!file) return;
      // Create an object of formData
      const formData = new FormData();

      const uid = new Date().getTime();

      // Update the formData object
      formData.append("file", file, file?.name);
      formData.append("folder", props?.folder);
      formData.append("type", props?.type);
      formData.append("app_id", "2");
      formData.append("secure_code", "remix");
      formData.append("uid", uid);

      // Send formData object
      const result = await api.post(`${endpoint}/file/upload`, formData);
      if (get(result, "data.code") !== 200)
        throw new Error(get(result, "data.msg"));
      handleSuccess({
        file: get(file, "file"),
        uid,
        url: get(result, "data.data.thumb"),
        id: get(result, "data.data.id"),
      });
    } catch (err) {
      console.error(err);
      handleUploadFailed({ file });
    }
  };

  const onFileChange = (event) => {
    onFileUpload(event.target.files[0]);
  };

  return (
    <div className="upload-container">
      <input
        type="file"
        ref={ref}
        onChange={onFileChange}
        accept="image/*"
        disabled={disabled}
      />
      <div
        type="button"
        className="btn-upload text-decoration-none emoji-btn"
        id="emoji-btn"
        onClick={() => ref.current?.click()}
      >
        {image ? (
          <img src={image} alt="" />
        ) : (
          <img
            src={iconUpImg}
            alt=""
            style={{ width: "50px", height: "50px" }}
          />
        )}
      </div>
    </div>
  );
}

export default UploadFile;
