import React from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faGear,
  faJournalWhills,
  faRectangleList,
  faShield,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";

const LeftNav = observer(() => {
  const {
    authStore: { clear },
  } = useStores();
  const location = useLocation();
  const pathName = location?.pathname;

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  return (
    <div className="css-foka8b">
      <Link
        to="/user"
        className={pathName === "/user" ? "css-z87e9z" : "css-6ijtmk"}
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faUser}
            className={pathName === "/user" ? "css-14thuu2" : "css-hd27fe"}
          />
          <div className={pathName === "/user" ? "css-iizq59" : "css-1n0484q"}>
            Account setup
          </div>
        </div>
      </Link>
      <Link
        to="/user/mybill"
        className={pathName === "/user/mybill" ? "css-z87e9z" : "css-6ijtmk"}
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faRectangleList}
            className={
              pathName === "/user/mybill" ? "css-14thuu2" : "css-hd27fe"
            }
          />
          <div
            className={
              pathName === "/user/mybill" ? "css-iizq59" : "css-1n0484q"
            }
          >
            Transaction log
          </div>
        </div>
      </Link>
      <Link
        to="/user/authrz"
        className={pathName === "/user/authrz" ? "css-z87e9z" : "css-6ijtmk"}
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faShield}
            className={
              pathName === "/user/authrz" ? "css-14thuu2" : "css-hd27fe"
            }
          />
          <div
            className={
              pathName === "/user/authrz" ? "css-iizq59" : "css-1n0484q"
            }
          >
            Verification
          </div>
        </div>
      </Link>
      <Link
        to="/user/respwd"
        className={pathName === "/user/respwd" ? "css-z87e9z" : "css-6ijtmk"}
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faGear}
            className={
              pathName === "/user/respwd" ? "css-14thuu2" : "css-hd27fe"
            }
          />
          <div
            className={
              pathName === "/user/respwd" ? "css-iizq59" : "css-1n0484q"
            }
          >
            Change Password
          </div>
        </div>
      </Link>
      <Link to="/" className="css-6ijtmk" onClick={() => onLogout()}>
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faRectangleList}
            className={
              pathName === "/user/mybill" ? "css-14thuu2" : "css-hd27fe"
            }
          />
          <div
            className={
              pathName === "/user/mybill" ? "css-iizq59" : "css-1n0484q"
            }
          >
            Log out
          </div>
        </div>
      </Link>
    </div>
  );
});

export default LeftNav;
