import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { get } from "lodash";
import ModalBet from "./ModalBet";

export default function GameBoard({ game_id, data_price, balanceMain }) {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame } = useNotify();
  const {
    authStore: { user, display_balance, loading, setLoading, general },
  } = useStores();
  const typeBet = [
    {
      type: "price",
      text: i18next.t("market"),
    },
    {
      type: "price_pending",
      text: i18next.t("limit"),
    },
  ];
  const [game_type, setGameType] = useState("buy");
  const [bet_type, setBetType] = useState(typeBet[0].type);
  const [amount, setAmount] = useState(0);
  const [last_price, setLastPrice] = useState(get(data_price, "lastPrice", 0));
  const [openModal, setOpenModal] = useState(false);
  const [quantity, setQuantity] = useState(0);

  const volume = useMemo(() => {
    const lastPrice = Number(data_price?.lastPrice);

    return Number(amount) * lastPrice;
  }, [amount, data_price]);

  const handleChangeInput = (event) => {
    if (!event?.target?.value) {
      setAmount();
    } else {
      setAmount(Number(event?.target?.value));
    }
  };

  useEffect(() => {
    setLastPrice(data_price !== undefined && Number(data_price?.lastPrice));
  }, [bet_type]);

  return (
    <div className="feature-board">
      <div className="group-action">
        <button
          type="button"
          className={classNames("btn btn-buy ", {
            active: game_type === "buy",
          })}
          onClick={() => setGameType("buy")}
        >
          {i18next.t("buy_2")}
        </button>
        <button
          type="button"
          className={classNames("btn btn-sell ", {
            active: game_type === "sell",
          })}
          onClick={() => setGameType("sell")}
        >
          {i18next.t("sell_2")}
        </button>
      </div>
      <div className="formbox_op mb-[20px]">
        <p>{i18next.t("transaction_mode")}</p>
        <select onChange={(e) => setBetType(e?.target?.value)}>
          {typeBet?.map((item) => (
            <option
              key={item?.type}
              value={item?.type}
              onClick={() => setBetType(item.type)}
            >
              {item?.text}
            </option>
          ))}
        </select>
      </div>
      {bet_type === "price" && (
        <>
          <p className="mt-12">{i18next.t("price")}</p>
          <div
            className="qty-input"
            style={{
              width: "100%",
            }}
          >
            <input
              className="sbui-input sbui-input--large"
              readOnly
              value={formatNumber(data_price?.lastPrice, "0,0.[0000000]")}
              style={{
                width: "100%",
              }}
            />
            <button
              type="button"
              className="btn btn-add"
              style={{
                width: 64,
              }}
            >
              USDT
            </button>
          </div>
        </>
      )}
      {bet_type === "price_pending" && (
        <>
          <div
            className="qty-input"
            style={{
              width: "100%",
            }}
          >
            <input
              placeholder={user?.balance}
              type="number"
              inputMode="decimal"
              className="sbui-input sbui-input--large"
              min={0}
              value={last_price}
              onChange={(e) => setLastPrice(e?.target?.value)}
            />
            <button
              type="button"
              className="btn btn-add"
              onClick={() =>
                setLastPrice(last_price > 1000 && Number(last_price) - 1000)
              }
            >
              -
            </button>
            <button
              type="button"
              className="btn btn-add"
              onClick={() => setLastPrice(Number(last_price) + 1000)}
            >
              +
            </button>
          </div>
          {/* <div
            className="qty-input mt-[10px]"
            style={{
              width: "100%",
            }}
          >
            <input
              placeholder="Số lượng"
              type="number"
              inputMode="decimal"
              className="sbui-input sbui-input--large"
              min={0}
              value={quantity}
              onChange={(e) => setQuantity(e?.target?.value)}
            />
            <button
              type="button"
              className="btn btn-add"
              onClick={() => setQuantity(quantity > 0.001 && quantity - 0.001)}
            >
              -
            </button>
            <button
              type="button"
              className="btn btn-add"
              onClick={() =>
                setQuantity(
                  quantity === "" || quantity === 0
                    ? quantity + 0.01
                    : quantity + 0.001
                )
              }
            >
              +
            </button>
          </div> */}
        </>
      )}
      {/* <div className="quantity-type">
        {buttons.map((item) => (
          <button
            key={`num-${item.toString()}`}
            type="button"
            className={classNames("btn", { active: amount_type === item })}
            onClick={() => {
              const balance = user?.balance;

              setAmountType(item);
              setAmount((balance * item) / 100);
            }}
          >
            <div className="blbox_3" />
            {item}%
          </button>
        ))}
      </div> */}

      <p className="mt-[15px]">{i18next.t("amount_2")}</p>
      <div className="qty-input !w-full">
        <input
          placeholder={i18next.t("transaction_amount")}
          type="number"
          inputMode="decimal"
          className="sbui-input sbui-input--large"
          min={0}
          step="0.000001"
          value={amount}
          onChange={handleChangeInput}
        />
        <button
          type="button"
          className="btn btn-add"
          style={{
            width: 64,
          }}
        >
          {game_id?.split("usdt")?.[0]}
        </button>
      </div>
      <div className="info mt-[20px]">
        <span className="text-[#707A8A]">{i18next.t("usable")}</span>
        {game_type === "buy" ? (
          <span className="text-[#707A8A]">
            {formatNumber(user?.balance, "0,0.[00000]")}&nbsp;USDT
          </span>
        ) : (
          <span className="text-[#707A8A]">
            {formatNumber(balanceMain?.amount, "0,0.[00000]")}&nbsp;
            {balanceMain?.name}
          </span>
        )}
      </div>
      <div className="info mt-[5px]">
        <span className="text-[#707A8A]">{i18next.t("volume")}</span>
        <span className="text-[#707A8A]">
          {formatNumber(volume, "0,0.[00000]")}&nbsp;USDT
        </span>
      </div>
      <div className="button-footer mt-[20px]">
        {game_type === "buy" && (
          <button
            type="button"
            className="btn btn-buy uppercase"
            onClick={() => setOpenModal(true)}
          >
            {i18next.t("buy_2")} {game_id?.split("usdt")?.[0]}
          </button>
        )}
        {game_type === "sell" && (
          <button
            type="button"
            className="btn btn-sell uppercase"
            onClick={() => setOpenModal(true)}
          >
            {i18next.t("sell_2")} {game_id?.split("usdt")?.[0]}
          </button>
        )}
      </div>
      {openModal && (
        <ModalBet
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={{
            symbol: balanceMain?.symbol,
            game_id,
            game_type,
            amount,
          }}
        />
      )}
    </div>
  );
}
