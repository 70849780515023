import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import { formatNumber } from "_common/utils/formatValue";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";
import Usdt from "./Usdt";
import Option from "./Option";
import Contract from "./Contract";

const MarketPage = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games },
  } = useStores();

  const [gameList, setGameList] = useState(games);
  const [activeTab, setActiveTab] = useState("contract");

  const handleKeydown = (event) => {
    if (event?.keyCode === 13) {
      const valueKey = event?.target?.value;
      const cloneData = [...gameList];
      const result = cloneData.filter(
        (obj) => obj?.name?.toLowerCase()?.search(valueKey?.toLowerCase()) > -1
      );
      if (!valueKey) {
        setGameList(games);
      } else {
        setGameList(result);
      }
    }
  };

  return (
    <div className="home-page market-page">
      <div className="market-search">
        <div className="input-search">
          <FontAwesomeIcon icon={faSearch} />
          <input
            placeholder="Please enter currency"
            className="field-control"
          />
        </div>
      </div>
      <div className="market-header">
        <div className="tabs">
          <div
            className={classNames("tab", {
              active: activeTab === "self",
            })}
            onClick={() => setActiveTab("self")}
          >
            {i18next.t("self_selection")}
          </div>
          <div
            className={classNames("tab", {
              active: activeTab === "contract",
            })}
            onClick={() => setActiveTab("contract")}
          >
            {i18next.t("second_contract")}
          </div>
          <div
            className={classNames("tab", {
              active: activeTab === "trade",
            })}
            onClick={() => setActiveTab("trade")}
          >
            {i18next.t("coin_trade")}
          </div>
          <div
            className={classNames("tab", {
              active: activeTab === "standard",
            })}
            onClick={() => setActiveTab("standard")}
          >
            {i18next.t("standard_contract")}
          </div>
        </div>
      </div>
      <div className="mt-[30px]">
        {activeTab === "self" && <Option />}
        {activeTab === "contract" && <Contract />}
        {activeTab === "trade" && <Usdt />}
        {activeTab === "standard" && <Usdt />}
      </div>
    </div>
  );
});

export default MarketPage;
