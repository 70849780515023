import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { formatBalance } from "_common/utils/formatValue";
import icon1 from "assets/img/icon/recharge1.svg";
import icon2 from "assets/img/icon/withdraw1.svg";
import icon3 from "assets/img/icon/exchange1.svg";
import icon4 from "assets/img/icon/transfer1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { faRotate, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const General = observer(() => {
  const {
    authStore: { display_balance, setDisplayBalance, user, tokens },
  } = useStores();

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "vnd"),
    [user]
  );

  const [active, setActive] = useState("main");

  const listCoin = useMemo(() => {
    if (active === "coin")
      return tokens?.filter((obj) => obj?.symbol === "btc");

    return tokens;
  }, [active, tokens]);

  return (
    <div id="general">
      <div className="flex flex-col w-full gap-10 ">
        <div className="flex flex-1">
          <div className="flex flex-col w-full gap-4">
            <div className="allmoneybox">
              <div className="allmbox_2">
                <span className="fzmm fcc text-white">
                  {i18next.t("account_balance")}(USDT)
                </span>
                <span
                  className="ml-[10px]"
                  onClick={() => setDisplayBalance(!display_balance)}
                >
                  <FontAwesomeIcon
                    icon={display_balance ? faEye : faEyeSlash}
                  />
                </span>
                <span
                  className="ml-[10px]"
                  onClick={() => window.location.reload()}
                >
                  <FontAwesomeIcon icon={faRotate} />
                </span>
              </div>
              <div className="allmbox_3 mt-[25px]">
                <span className="zhehebox text-[26px] text-white">
                  {formatBalance(user?.balance, display_balance)}
                </span>
              </div>
              <div className="allmbox_btn">
                <div className="allmbox_btn_1">
                  <Link to="/member/recharge">
                    <div className="allmbox_cbtn ">
                      <img src={icon1} alt="" width="23" className="mr-[5px]" />
                      <span className="text-white">
                        {i18next.t("recharge")}
                      </span>
                    </div>
                  </Link>
                  <Link to="/member/withdraw">
                    <div className="allmbox_tbtn ">
                      <img src={icon2} alt="" width="23" className="mr-[5px]" />
                      <span className="text-white">
                        {i18next.t("withdraw")}
                      </span>
                    </div>
                  </Link>
                  <Link to="/board/exchange">
                    <div className="allmbox_tbtn ">
                      <img src={icon3} alt="" width="23" className="mr-[5px]" />
                      <span className="text-white">
                        {i18next.t("flash_exchange")}
                      </span>
                    </div>
                  </Link>
                  <Link to="/member/transfer">
                    <div className="allmbox_tbtn ">
                      <img src={icon4} alt="" width="23" className="mr-[5px]" />
                      <span className="text-white">
                        {i18next.t("transfer")}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="findbox_1">
              <div className="findbox_2">
                <div className="findbox_3">
                  <span className="fzmm fcc">Danh sách tài sản</span>
                </div>
              </div>
            </div>
            <div className="list-token">
              {listCoin?.map((item) => (
                <div key={item} className="listbox">
                  <div className="listbox_title">
                    <div className="listbox_title_l">
                      <span className="fcc text-[16px] text-[500]">
                        {item?.name}
                      </span>
                    </div>
                  </div>
                  <div className="w-full h-[60px]">
                    <div className="w-[33.33%] h-[60px] inline-block">
                      <div className="w-full h-[30px] leading-[40px]">
                        <span className="fcc">{i18next.t("available")}</span>
                      </div>
                      <div className="w-full h-[30px] leading-[20px]">
                        <span className="fcc f12" id="num_usdt">
                          {formatBalance(item?.amount, display_balance)}
                        </span>
                      </div>
                    </div>
                    <div className="w-[33.33%] h-[60px] inline-block">
                      <div className="w-full h-[30px] leading-[40px]">
                        <span className="fcc">{i18next.t("occupation")}</span>
                      </div>
                      <div className="w-full h-[30px] leading-[20px]">
                        <span className="fcc f12" id="numd_usdt">
                          {formatBalance(item?.pending_amount, display_balance)}
                        </span>
                      </div>
                    </div>
                    <div className="w-[33.33%] h-[60px] inline-block">
                      <div className="w-full h-[30px] leading-[40px] text-right">
                        <span className="fcc">
                          {i18next.t("Equivalent(USDT)")}
                        </span>
                      </div>
                      <div className="w-full h-[30px] leading-[20px] text-right">
                        <span className="fcc f12" id="zhehe_usdt">
                          {formatBalance(item?.convert_amount, display_balance)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default General;
