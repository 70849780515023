import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { useNotify } from "_common/component/NotifyProvider";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import imgEmpty from "assets/img/empty-dark.png";
import { GamePlayerApi } from "states/api/gamePlayer";
import moment from "moment";
import { formatNumber } from "_common/utils/formatValue";
import {
  gameBetItemType,
  gameBetSpotStatus,
  gameBetSpotType,
} from "_common/constants/statusType";
import classNames from "classnames";

const HistoryFeature = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();

  const [status, setStatus] = useState("bet");
  const { data, refetch } = useQuery(
    ["game-player", "feature", status],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 50,
          status,
          type: "feature",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="w-full min-h-[360px] bg-[#151617] border-t-[1px] border-[#2c2d2e] mt-[10px]">
      <div className="order-top">
        <div
          className={`order-top-span order-top-current fcfs ${
            status === "bet" && "order-top-select"
          } `}
          onClick={() => setStatus("bet")}
        >
          Pending order
        </div>
        <div
          className={`order-top-span order-top-current fcfs ${
            status === "complete" && "order-top-select"
          } `}
          onClick={() => setStatus("complete")}
        >
          Order history
        </div>
        <div className="refresh-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="inline"
          >
            <path
              d="M42 8V24"
              stroke="#303131"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 24L6 40"
              stroke="#303131"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 24C6 33.9411 14.0589 42 24 42C28.8556 42 33.2622 40.0774 36.5 36.9519"
              stroke="#303131"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M42.0007 24C42.0007 14.0589 33.9418 6 24.0007 6C18.9152 6 14.3223 8.10896 11.0488 11.5"
              stroke="#303131"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="order-main">
        <div className="table-box order-main-table-current">
          <table className="table fck order-main-table w-full">
            <thead>
              <tr className="fccs">
                <td>Time</td>
                <td>Trade pairs</td>
                <td>Amount</td>
                <td>Price</td>
                <td>Total (USDT)</td>
                <td>Average price</td>
                <td>Status</td>
                <td>Direction</td>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((item) => (
                <tr className=" f12" key={item?.id}>
                  <td className="fck f14">
                    {item?.created_at &&
                      moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td className="fck f14">{item?.game?.name}</td>
                  <td className="fck f14">
                    {formatNumber(item?.amount, "0,0.[0000]")}
                  </td>
                  <td className="fck f14">
                    {formatNumber(item?.open, "0,0.[0000]")}
                  </td>
                  <td className="fck f14">
                    {formatNumber(item?.amount, "0,0.[0000]")}
                  </td>
                  <td className="fck f14">
                    {formatNumber(item?.open, "0,0.[0000]")}
                  </td>
                  <td className="fck f14">{gameBetSpotStatus[item?.status]}</td>
                  <td
                    className={classNames("f14", {
                      "text-red": item?.bet_game === "sell",
                      "text-green": item?.bet_game === "buy",
                    })}
                  >
                    {gameBetSpotType[item?.bet_game]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!data?.data?.length && (
            <div className="table-history-more">
              <img src={imgEmpty} alt="" className="empty-svg inline" />
              <p className="fccs">Chưa có giao dịch</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default HistoryFeature;
