import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";

const listTime = [
  {
    value: "line",
    text: "Realtime",
  },
  {
    value: "1m",
    text: "1min",
  },
  {
    value: "5m",
    text: "5min",
  },
  {
    value: "15m",
    text: "15min",
  },
  {
    value: "30m",
    text: "30min",
  },
  {
    value: "1h",
    text: "1hour",
  },
  {
    value: "1d",
    text: "1day",
  },
  {
    value: "1w",
    text: "1week",
  },
];

export default function GameChart({ game_id, isLight }) {
  const {
    authStore: { trading_view },
  } = useStores();
  const [time, setTime] = useState(listTime[1]?.value);

  return (
    <div className="w-full h-[450px] bg-[#1a1b1c] p-[5px]">
      <div className="w-full h-full p-[8px]">
        <div className="changInterval flex">
          {listTime?.map((item) => (
            <div
              key={item?.value}
              className={classNames("interval-item fcf", {
                active: time === item?.value,
              })}
              onClick={() => setTime(item?.value)}
            >
              {item.text}
            </div>
          ))}
        </div>
        <div id="highcharts">
          <iframe
            title="chart"
            src={`${trading_view}/v4?symbol=${game_id}&color=rgb(26,27,28)&theme=${
              isLight ? "light" : "dark"
            }&type=${time}`}
            width="100%"
            height="100%"
            style={{ height: 400 }}
          />
        </div>
      </div>
    </div>
  );
}
