import React, { useEffect, useState } from "react";

import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BankApi } from "states/api/bank";
import { optionBank } from "_common/constants/bank";

import { Portal } from "react-portal";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

const schema = yup
  .object({
    bank_owner: yup.string().required(i18next.t("verify_require")),
    bank_number: yup.string().required(i18next.t("verify_require")),
    bank_name: yup.string().required(i18next.t("verify_require")),
  })
  .required();

export default function AddBankForm() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  const [params] = useSearchParams();

  const bank_type = params?.get("bank_type");

  const { mutate, isLoading } = useMutation(
    (variables) => BankApi.create({ params: variables }),
    {
      onSuccess: (res) => {
        navigate(-1);
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = (values) => {
    if (isLoading) return;
    trigger();

    mutate({
      ...values,
      user_id: user?.id,
    });
  };

  return (
    <div className="container bank-page mt-10">
      <HeaderTop title="Điền thông tin rút tiền" />
      <form onSubmit={handleSubmit(onSave)} className="py-[5px] px-[15px]">
        <div className="imgbox">
          <div className="imgbox_4 !border-[0] !m-[0]">
            <div className="imgbox_5">
              <span className="fzmmm fcc f12">Danh sách ngân hàng</span>
            </div>
            <div className="zz_inputbox">
              <select
                placeholder={i18next.t("type_kyc")}
                className="inputbox-select"
                disabled={user?.bank_owner}
                {...register("bank_name")}
              >
                {optionBank.map((bank) => (
                  <option value={bank.value}>
                    {bank.text} ({bank?.sortName})
                  </option>
                ))}
              </select>
            </div>
            <div className="imgbox_5">
              <span className="fzmmm fcc f12">Tên thật</span>
            </div>
            <div className="zz_inputbox">
              <input
                type="text"
                placeholder="Vui lòng nhập tên thật của bạn"
                className="zz_input"
                value={user?.bank_owner}
                disabled={user?.bank_owner}
                {...register("bank_owner")}
              />
            </div>
            <div className="imgbox_5">
              <span className="fzmmm fcc f12">Số tài khoản</span>
            </div>
            <div className="zz_inputbox">
              <input
                type="text"
                placeholder="Nhập hoặc nhấn dài địa chỉ dán"
                className="zz_input"
                value={user?.bank_number}
                disabled={user?.bank_owner}
                {...register("bank_number")}
              />
            </div>
          </div>
        </div>
        <div className="btnbox">
          <button type="submit" disabled={!isValid} className="btnaddress">
            <span className="fzmm fcc">Lưu</span>
          </button>
        </div>
      </form>
    </div>
  );
}
