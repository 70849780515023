import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useStores } from "_common/hooks";
import i18next from "i18next";

function ModalSafety({ open, onClose }) {
  const {
    authStore: { general },
  } = useStores();

  return (
    <Portal>
      <div
        className="modal modal-full"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="font-bold">Safety</h2>
            <div className="btn-close" onClick={() => onClose()}>
              X
            </div>
          </div>
          <div className="classic-bet-now">
            <div className="content">
              <div className="rules__content">
                <p>
                  All PFunds systems require multiple authentications to access,
                  including hardware authentication. Apart from approved and
                  supervised channels, individual systems cannot communicate
                  with each other.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  PFunds's trading engine is unique, programmed in kdb+
                  language, which is a tool set frequently used by major banks
                  in high-frequency trading applications, PFunds's trading
                  system has unprecedented speed and reliability. And this speed
                  advantage not only allows us to have more transactions and
                  operations per second, PFunds will also review the accounts of
                  the entire system in each pending order, delivery, and fund
                  withdrawal. At any time, the sum of profit and loss of all
                  accounts in the system must be 0, otherwise trading will be
                  suspended.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  <b>Security of the trading engine</b>
                </p>
                <p>
                  PFunds is aimed at senior investors, providing programmed and
                  systematic trading solutions, advocating investors to make
                  more rational and professional investment decisions in a
                  self-disciplined, programmable and verifiable way. PFunds
                  provides clients with CFD, gold, energy, foreign exchange, and
                  digital currency margin futures contracts. Unlike futures,
                  margin futures contracts do not have an expiration date. As
                  long as there is sufficient margin in the account, the
                  customer can hold the contract, or until it is traded again.
                  Margin trading is a form of speculation in financial markets
                  that uses leverage to magnify investments. Leverage tools can
                  help clients open positions in the market. Customers only need
                  to invest in margin, and do not need to invest all the funds
                  required to open a position.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalSafety;
