import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { PriceApi } from "states/api/price";
import { useQuery } from "@tanstack/react-query";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import i18next from "i18next";

import iconNav21 from "assets/img/icon/icon21.png";
import iconNav13 from "assets/img/icon/icon13.png";
import iconNav14 from "assets/img/icon/icon14.png";
import iconNav17 from "assets/img/icon/icon17.png";
import iconNav19 from "assets/img/icon/icon19.png";
import iconNav31 from "assets/img/icon/icon31.png";
import iconNav32 from "assets/img/icon/icon32.png";
import iconNav33 from "assets/img/icon/icon33.png";
import icon9 from "assets/img/icon/icon9.png";
import icon10 from "assets/img/icon/icon10.png";
import iconUser from "assets/img/icon-user.png";
import SelectLanguage from "_common/component/SelectLanguage";

const MobileHome = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { user, banners, top_coin, games, authenticated, logo, general },
  } = useStores();

  const [activeTab, setActiveTab] = useState("currency");

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinTopPrices = useMemo(
    () =>
      top_coin.map((item) => {
        const price = data?.find((obj) => obj?.symbol === item?.code);

        return { ...item, price };
      }),
    [top_coin, data]
  );

  const coinListPrice = useMemo(
    () =>
      games.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [games, data]
  );

  return (
    <div className="home-page">
      <div className="header_box flex items-center justify-between h-[50px] mb-[10px]">
        <div className="flex-1 pl-[1rem]">
          <div className="logo flex items-center gap-1">
            <img
              src={logo}
              alt=""
              className="w-[22px] h-[14px] rounded-[50%]"
            />
            <div className="website-name">{general?.name_website}</div>
          </div>
        </div>
        <div className="w-[158px] pr-[1rem] text-right">
          {authenticated ? (
            <Link to="/member" className="inline-block">
              <img
                className="w-[32px] h-[32px] rounded-[50%]"
                src={user?.avatar_url}
                alt=""
              />
            </Link>
          ) : (
            <>
              <Link
                to="/auth/login"
                className="font-bold text-[16px] text-gray-500"
              >
                {i18next.t("login")} {` `}/{` `}
              </Link>
              <Link
                to="/auth/register"
                className="font-bold text-[16px] text-gray-500"
              >
                {i18next.t("register")}
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="home-container">
        <div className="flex">
          <Swiper
            spaceBetween={30}
            centeredSlides
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="swiper2"
          >
            {banners.map((item) => (
              <SwiperSlide key={item?.id}>
                {item.type === "image" && (
                  <a href="/">
                    <img alt="" src={item?.image_url} height="148" />
                  </a>
                )}
                {item.type === "video" && (
                  <iframe
                    width="430"
                    height="287"
                    src={item?.src}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    title="video"
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="h-[65px] mt-[0.7rem]">
          <div className="top-coin">
            {coinTopPrices?.map((item) => (
              <Link key={item?.code} to="/" className="item">
                <h5>
                  <b>{item?.name?.split("/")?.[0]}</b>/
                  <span>{item?.name?.split("/")?.[1]}</span>
                </h5>
                <p
                  className={classNames({
                    "text-green": item?.price?.priceChangePercent > 0,
                    "text-red": item?.price?.priceChangePercent <= 0,
                  })}
                >
                  {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
                </p>
                <h4
                  className={classNames("price", {
                    "text-green": item?.price?.priceChangePercent > 0,
                    "text-red": item?.price?.priceChangePercent <= 0,
                  })}
                >
                  {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
                </h4>
              </Link>
            ))}
          </div>
        </div>
        <div className="h-[170px]  rounded-[20px] py-[5px] px-[8px] text-[#868d9a ] mt-[10px]">
          <div className="w-full h-[80px] flex flex-wrap">
            <Link to="/board/exchange" className="w-[25%] h-[80px]">
              <div className="oplist text-center">
                <div className="opimg leading-[50px]">
                  <img
                    src={iconNav21}
                    alt=""
                    className="w-[35px] inline-block"
                  />
                </div>
                <div className="optext">
                  <span className="text-[13px] ">
                    {i18next.t("flash_exchange")}
                  </span>
                </div>
              </div>
            </Link>
            <Link to="/trade" className="w-[25%] h-[80px]">
              <div className="oplist text-center">
                <div className="opimg leading-[50px]">
                  <img
                    src={iconNav13}
                    alt=""
                    className="w-[35px] inline-block"
                  />
                </div>
                <div className="optext">
                  <span className="text-[13px] ">
                    {i18next.t("second_contract")}
                  </span>
                </div>
              </div>
            </Link>
            <Link to="/trade" className="w-[25%] h-[80px]">
              <div className="oplist text-center">
                <div className="opimg leading-[50px]">
                  <img
                    src={iconNav14}
                    alt=""
                    className="w-[35px] inline-block"
                  />
                </div>
                <div className="optext">
                  <span className="text-[13px] ">
                    {i18next.t("coin_trade")}
                  </span>
                </div>
              </div>
            </Link>
            <Link to="/member/recharge" className="w-[25%] h-[80px]">
              <div className="oplist text-center">
                <div className="opimg leading-[50px]">
                  <img
                    src={iconNav17}
                    alt=""
                    className="w-[35px] inline-block"
                  />
                </div>
                <div className="optext">
                  <span className="text-[13px] ">
                    {i18next.t("quick_deposit")}
                  </span>
                </div>
              </div>
            </Link>
            <Link to="/cskh" className="w-[25%] h-[80px]">
              <div className="oplist text-center">
                <div className="opimg leading-[50px]">
                  <img
                    src={iconNav19}
                    alt=""
                    className="w-[35px] inline-block"
                  />
                </div>
                <div className="optext">
                  <span className="text-[13px] ">{i18next.t("support")}</span>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="banner-double">
          <Link to="/member/recharge" className="banner-item">
            <img src={iconNav31} alt="" className="img-left" />
            <div className="desc">{i18next.t("quick_recharge")}</div>
            <img src={iconNav33} alt="" className="img" />
          </Link>
          <Link to="/member/withdraw" className="banner-item">
            <img src={iconNav32} alt="" className="img-left" />
            <div className="desc">{i18next.t("quick_withdraw")}</div>
            <img src={iconNav33} alt="" className="img-right" />
          </Link>
        </div>
        <div className="mt-[32px]">
          <div className="tabs">
            <div
              className={classNames("tab", {
                active: activeTab === "currency",
              })}
              onClick={() => setActiveTab("currency")}
            >
              {i18next.t("popular_currency")}
            </div>
            <div
              className={classNames("tab", {
                active: activeTab === "forex",
              })}
              onClick={() => setActiveTab("forex")}
            >
              {i18next.t("foreign_currency")}
            </div>
            <div
              className={classNames("tab", {
                active: activeTab === "gold",
              })}
              onClick={() => setActiveTab("gold")}
            >
              {i18next.t("internal_gold")}
            </div>
          </div>
        </div>
        <div className="coin-list pb-[1rem] mt-[1rem]">
          <div className="list">
            <div className="item header !static !transform-none !items-start">
              <div className="col1 text-[#787e8c] !items-start !justify-start">
                {i18next.t("coin_name")}
              </div>
              <div className="col2 text-[#787e8c] !justify-end">
                {i18next.t("last_price")}
              </div>
              <div className="col3 text-[#787e8c] text-right">
                {i18next.t("coin_change")}
              </div>
            </div>
            {coinListPrice?.map((item) => (
              <Link
                className="item"
                to={`/trade?symbol=${item?.code}`}
                key={item?.id}
              >
                <div className="col1 !flex-row">
                  <img
                    src={item?.image_url}
                    alt=""
                    width="20"
                    height="20"
                    className="mr-[10px]"
                  />
                  <p className="name">
                    <b>{item?.name?.split("/")?.[0]}</b>
                    <span>/USDT</span>
                  </p>
                </div>
                <div className="col2 !justify-end">
                  {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
                </div>
                <div className="col3">
                  <span
                    className={classNames("tag ", {
                      green: item?.price?.priceChangePercent > 0,
                      red: item?.price?.priceChangePercent <= 0,
                    })}
                  >
                    {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default MobileHome;
